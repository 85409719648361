import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PageTitle from '@johnlewispartnership/wtr-ingredients/ingredients/PageTitle';
import PageTitleDeprecated from 'components/PageTitle';
import { getCategoryById } from 'redux/modules/taxonomy/selectors';
import { getFeatureFlags } from 'utils/feature-flags';

type PageTitleProps = {
  analyticsTags: object;
  componentId: string;
  cqResponsiveClasses: string[];
  title: string;
  subtitle: string;
  styleIds: string[];
  componentPlaceholder: string;
  showGeneratedTitle: boolean;
};

const WrappedPageTitle = ({
  analyticsTags,
  componentId,
  cqResponsiveClasses,
  styleIds = [],
  subtitle,
  title,
  showGeneratedTitle,
}: PageTitleProps) => {
  const hasSubTitle = styleIds.includes('title_with_subtitle');

  // TODO: remove logic after completion of https://www.jlpit.com/jira/browse/WPIP-54800
  const category = useSelector(state =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getCategoryById(state, state.search?.queryParams?.category),
  );

  const { browse_showSecondaryPageTitle: showSecondaryPageTitleFeatureFlag } = getFeatureFlags();
  const secondaryPageTitle = category?.pageTitle;
  const showSecondaryPageTitle =
    showSecondaryPageTitleFeatureFlag && secondaryPageTitle && showGeneratedTitle;
  const pageTitle = showSecondaryPageTitle ? secondaryPageTitle : title;

  return (
    <section id={componentId} className={classNames(cqResponsiveClasses)} {...analyticsTags}>
      {hasSubTitle ? (
        <PageTitle title={pageTitle} subtitle={subtitle} />
      ) : (
        <PageTitleDeprecated text={pageTitle} uppercase display underline />
      )}
    </section>
  );
};

export default WrappedPageTitle;
