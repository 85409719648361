import React from 'react';
import pure from 'recompose/pure';
import PropTypes from 'prop-types';

import { Bin } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { SmallButton } from '@johnlewispartnership/wtr-ingredients/ingredients/SmallButton';

import styles from './DeleteButton.scss';

const DeleteButton = pure(({ action, title }) => {
  const buttonProps = {
    className: styles.delButton,
    onClick: event => {
      event.preventDefault();
      action();
    },
    title,
    theme: 'primaryWhite',
    startIcon: <Bin data-testid="icon" />,
  };

  return <SmallButton {...buttonProps} />;
});

DeleteButton.displayName = 'DeleteButton';

DeleteButton.propTypes = {
  action: PropTypes.func.isRequired,
};

export default DeleteButton;
