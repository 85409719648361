import { connect } from 'react-redux';

import history from 'utils/history';
import urls from 'constants/urls';

import { deleteShoppingList } from 'redux/modules/shopping-list/actions/delete-shopping-list';
import { getShoppingListName } from 'redux/modules/entities/selectors/shopping-lists';

import DeleteButton from './ShoppingListDeleteButton';

export default connect(
  (state, { listId }) => ({
    name: getShoppingListName(state, listId),
  }),
  (dispatch, { listId, redirect }) => ({
    deleteShoppingList: () => {
      dispatch(deleteShoppingList(listId)).then(() => {
        if (redirect) {
          history.push(urls.lists);
        }
      });
    },
  }),
)(DeleteButton);
