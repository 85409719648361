import shoppinglist from 'api/definitions/shoppinglist';

import {
  SHOPPING_LIST_DELETED,
  SHOPPING_LIST_PENDING,
  SHOPPING_LIST_REJECTED,
} from 'redux/modules/shopping-list/actions/types';

const notFoundStatuses = [400, 404];

export const deleteShoppingList = shoppingListId => dispatch =>
  dispatch({
    types: [SHOPPING_LIST_PENDING, SHOPPING_LIST_DELETED, SHOPPING_LIST_REJECTED],
    apiCall: shoppinglist.delete({
      shoppingListId,
    }),
    listId: shoppingListId,
    doNotHandleHttpStatuses: notFoundStatuses,
  });
