import React from 'react';
import { func } from 'prop-types';
import pure from 'recompose/pure';

import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import { Pen } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './EditButton.scss';

const EditButton = pure(({ onClick }) => {
  const buttonProps = {
    className: styles.editButton,
    name: 'Edit',
    title: 'Edit',
    startIcon: <Pen data-testid="icon" />,
    onClick,
    theme: 'primaryWhite',
  };

  return <Button {...buttonProps} />;
});

EditButton.displayName = 'EditButton';

EditButton.propTypes = {
  onClick: func,
};

EditButton.defaultProps = {
  onClick: () => {},
};

export default EditButton;
