import React from 'react';
import { svgIcon } from 'utils/svg-icon';
import {
  colourSuccessGreen,
  colourCorporateGreen,
  colourCorporateLimeGreen,
} from '@johnlewispartnership/wtr-ingredients/foundations/colours';

const InstoreFavouritesIcon = svgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 40" aria-hidden="true">
    <path
      fill={colourSuccessGreen}
      d="M29.562 3.333c6.112 0 10.938 4.541 10.938 11.676 0 5.19-3.646 10.703-9.651 16.325-2.038 1.945-4.183 3.675-6.435 5.297-.75.54-1.501 1.08-2.145 1.513-.214.108-.428.325-.643.433-.107.108-.107.108-.215.108l-.965.648-.858-.54c-.107-.108-.428-.324-.857-.54-.644-.433-1.395-.974-2.145-1.514-2.145-1.622-4.397-3.351-6.434-5.297C5.433 27.009 2.109 22.577.928 18.36.607 17.28.5 16.198.5 15.117.822 7.874 5.647 3.333 11.76 3.333c3.432 0 6.756 1.406 9.008 4.217 2.252-2.811 5.576-4.216 8.794-4.216Z"
    />
    <path
      fill={colourCorporateGreen}
      d="M52.5 10.8h-28a2 2 0 0 0-2 2v18.4a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V12.8a2 2 0 0 0-2-2Z"
    />
    <path
      fill={colourCorporateLimeGreen}
      d="M38.75 33.2c-.275-.76-.417-1.593-.48-2.263l-.035-.373c-.207-2.18-.395-4.165.237-6.411.228-.813.636-1.569 1.04-2.316.294-.546.587-1.088.805-1.644.266-.68.467-1.445.666-2.2.073-.274.145-.548.22-.815.074-.267.154-.538.234-.806.227-.764.45-1.516.532-2.156.078-.605-.01-1.272-.101-1.957-.064-.484-.13-.975-.138-1.46h3.33c-.161.268-.346.547-.55.853-.47.709-1.037 1.563-1.634 2.757-.91 3.55-1.65 7.19-1.706 10.168-.004.208-.013.444-.024.697-.063 1.54-.152 3.712.886 4.066.679.231 1.485-.828 2.047-1.565.103-.136.199-.262.283-.366l.387-.47c.4-.483.79-.953.971-1.241.257-.406.52-.921.798-1.466.905-1.77 1.97-3.855 3.483-3.527 1.474.32 1.598 2.99 1.665 4.436l.018.368c.033.577.05 1.111.068 1.62.055 1.607.102 2.95.565 4.535.024.081.049.172.075.27.12.443.273 1.006.508 1.266h-1.84a7.25 7.25 0 0 0-.284-.86c-.146-.352-.238-.906-.341-1.53-.285-1.715-.656-3.95-2.48-3.911-.96.02-1.835 1.877-2.574 3.441-.309.655-.594 1.26-.85 1.656l-.103.16c-.198.306-.434.672-.7 1.044H38.75ZM36.967 22.49c0-1.206-.472-2.928-1.943-3.462-.724-.263-1.265-.08-1.798.1-.438.147-.872.293-1.4.19-.557-.11-.944-.384-1.34-.664-.367-.26-.743-.526-1.269-.669-1.7-.463-3.001 1.926-3.957 3.682a79.22 79.22 0 0 1-.218.399l-.218.386c-.398.702-.734 1.294-.724 1.952.01.582.358 1.51 1.168 1.845.853.354 1.544-.385 1.764-1.307.06-.25.077-.576.095-.922.043-.811.092-1.742.708-2.12.506-.31.924.03 1.39.407.296.24.611.496.98.612.855.267 1.447-.305 2.014-.852.582-.563 1.137-1.1 1.92-.668 1.638.902.2 3.759-.962 4.905-.337.333-.78.49-1.218.646-.632.225-1.256.447-1.54 1.188-.21.545.078 1.284.608 1.643.975.66 2.162.373 2.778-.473.273-.375.405-.827.536-1.28.092-.315.184-.631.324-.92.294-.608.668-1.147 1.029-1.666.658-.947 1.271-1.83 1.273-2.952Z"
    />
  </svg>,
  'InstoreFavouritesIcon',
);

export default InstoreFavouritesIcon;
