import { apiCallback } from 'api';

const definition = {
  service: 'shopping-lists',
  endpoint: 'shopping-lists/${shoppingListId}',
  query: {
    summary: '${summary}',
  },
  verbs: ['get', 'put', 'delete'],
  defaultVersion: 1,
};

export default {
  get: apiCallback(definition, 'get'),
  put: apiCallback(definition, 'put'),
  delete: apiCallback(definition, 'delete'),
};
